class StickyVideo
{
    constructor(el) {
        this._element = el;
        this._item = $(el).find('.sticky-video-item').get(0)
        if (!this._item)
            return

        this._addEventListeners()
    }

    _addEventListeners() {
        let opts = {
            root: null,
            rootMargin: '0px',
            threshold: [.25,.75]
        }

        let obs = new IntersectionObserver(e => this._toggleSticky(e), opts)

        obs.observe( this._element )
    }

    _toggleSticky(e) {
        let obs = e[0]

        if (obs.isIntersecting) {
            return this._item.classList.remove('sticky-video-float')
        }


        let method = obs.boundingClientRect.top < 0 ? 'add' : 'remove'
        this._item.classList[method]('sticky-video-float')
    }
}

$(function(){
    let els = document.querySelectorAll('.sticky-video')
    if (!els.length)
        return

    els.forEach(e => {
        $(e).data('sticky-video', new StickyVideo(e))
    })
})

$(function() {
    let els = document.querySelectorAll('.btn-unstick')
    if (!els.length)
        return

    els.forEach(e => {
        $(e).click(function(){
            $(this).closest('.sticky-video').addClass('locked')
            $(this).remove();
        })
    })
})

export default StickyVideo
