class FormGallery
{
    constructor(element) {
        this._editIndex = null

        this._populateElements(element)
        this._addEventListeners()

        $(this.model).change()
    }

    _addEventListeners() {
        let that = this

        $(this.model).change(e => {
            this._updatePreview()
        })

        $(this.btnAdd).click(e => {
            let opts = {
                form: that.el.dataset.form,
                accept: 'image/*',
                multiple: true,
                success(res) {
                    res.forEach(e => {
                        if (e.error)
                            return

                        that.value.push({ url: e.data.url, label: ''})
                    })

                    that._updateModel()
                },
                error(error) {
                    console.log(error)
                },
                progress(percent, index, all) {
                    if (!all || all === 100)
                        return that.progress.classList.add('d-none')

                    that.progress.classList.remove('d-none')
                    that.progBar.style.width = all + '%'
                }
            }

            let picker = new FilePicker(opts)
            picker.start()
        })

        $(this.preview).on('click', '.btn-delete', e => {
            let parent = e.target.parentNode
            let index = parent.dataset.index

            that._updateModel()
            that.value.splice(index, 1)
            that.model.value = JSON.stringify(that.value)
            $(that.model).change()
        })

        $(this.el).closest('form')
            .submit(e => that._updateModel())
    }

    _hidePreview() {
        this.empty.classList.remove('d-none')
        this.preview.classList.add('d-none')
    }

    _populateElements(element) {
        this.$el      = $(element)
        this.el       = element
        this.editor   = this.$el.children('.editor').get(0)
        this.empty    = $(this.editor).children('.empty').get(0)
        this.preview  = $(this.editor).children('.preview').get(0)
        this.progress = $(this.editor).children('.progress').get(0)
        this.progBar  = $(this.progress).children('.progress-bar').get(0)
        this.model    = this.$el.children('.form-control').get(0)
        this.btnAdd   = this.$el.find('.btn-add').get(0)
    }

    _renderItem(item, index) {
        let img = item.url.replace(/\.([a-z]+)$/, '_66x66.$1')

        let html = `
            <div class="col" data-index="${index}">
                <div class="row g-2">
                    <div class="col-auto">
                        <img src="${img}" alt="#" data-src="${item.url}">
                    </div>
                    <div class="col" data-index="${index}">
                        <input type="text" placeholder="Label" class="form-control form-control-sm inp-label">
                        <button type="button" class="btn btn-danger btn-delete btn-sm mt-1">Delete</button>
                    </div>
                </div>
            </div>`

        let el = $(html)
        el.find('.form-control').val(item.label)
        el.find('img').click(e => {
            let url = e.target.dataset.src
            if(!url)
                return

            let img = document.createElement('img')
            img.src = url
            let viewer = new Viewer(img, {
                navbar: false,
                title: false,
                toolbar: false,
                movable: true,
                rotatable: false,
                scalable: false
            }).show()
        })

        $(this.preview).append(el)
    }

    _showPreview() {
        this.empty.classList.add('d-none')
        this.preview.classList.remove('d-none')
    }

    _updateModel() {
        let that = this
        $(this.preview).find('.inp-label').each((i,e) => {
            let parent = e.parentNode
            let index = parent.dataset.index
            that.value[index].label = e.value
        })

        this.model.value = JSON.stringify(this.value)
        $(this.model).change()
    }

    _updatePreview() {
        this.value = JSON.parse(this.model.value || '[]')

        if(!this.value.length)
            return this._hidePreview()

        this.preview.innerHTML = ''
        this.value.forEach((e, i) => {
            this._renderItem(e, i)
        })

        this._showPreview()
    }
}


$(function(){
    let els = document.querySelectorAll('.form-gallery')
    if(!els.length)
        return

    els.forEach(e => {
        e._formGallery = new FormGallery(e)
    })
})

export default FormGallery
