$(function(){
    let els = document.querySelectorAll('.splide')
    if (!els.length)
        return

    let splides = {}

    for(let i=0; i<els.length; i++){
        let el = els[i]
        let splide = new Splide(el)
        $(els[i]).data('splide', splide)

        // syncable
        if(el.dataset.sync) {
            let group = el.dataset.sync
            let type  = el.dataset.type

            if (!splides[group])
                splides[group] = {}

            splides[group][type] = splide

            if (type !== 'primary')
                splide.mount()
        } else {
            splide.mount()
        }
    }

    // sync the slides
    for (let k in splides) {
        let sync = splides[k]
        sync.primary.sync( sync.secondary ).mount()
    }

    $(document.body).on('click', '.splide-control', function(){
        let target = document.querySelector( this.dataset.target )
        let method = this.dataset.method
        let params = this.dataset.params
        let splide = $(target).data('splide')

        if (params)
            params = JSON.parse(params)

        splide[method].apply(splide, params)
    })
})

export default 'Splide'
