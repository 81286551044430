$(function(){
    let musicPlaying = false

    $('.ml-audio').each((i,e) => {
        let player  = e
        let visual  = e.dataset.visual
        let control = e.dataset.control ? $(e.dataset.control).get(0) : null
        let wave    = new Wave

        $(player)
            .on('pause play', e => {
                let paused  = player.paused
                let classes = ['paused', 'playing']
                if(!paused)
                    classes.reverse()
                control.classList.add(classes[0])
                control.classList.remove(classes[1])
                musicPlaying = !player.paused
            })
            .on('ended', e => {
                let modal = $('#music-auto-next')
                if (!modal.get(0))
                    return

                let confirm  = modal.find('.btn-confirm').get(0)
                let currTime = 4
                let timer    = setInterval(() => {
                    confirm.innerText = `Play ( ${currTime--} )`
                    if (currTime < 0)
                        location.href = confirm.href
                }, 1000)

                confirm.innerText = `Play ( 5 )`
                modal.modal('show')
                modal.on('hide.bs.modal', e => clearInterval(timer))
            })

        $(control).on('click', e => {
            let method = player.paused ? 'play' : 'pause'
            player[method]()
        })

        if(visual) {
            visual = $(visual)
            visual.attr({width: visual.width(), height: visual.height()})
            wave.fromElement(player.id, visual.attr('id'), {
                stroke: 1,
                type: 'orbs',
                colors: ['#FFF']
            })
        }

        if(player.classList.contains('autoplay')) {
            player.play();
        }
    })

    $(document.body).on('click', '.music-playable', function(e){
        let target = this.href
        if (musicPlaying)
            target += '?play=1'
        e.preventDefault()

        location.href = target
    })
})

export default 'Music'
