$(function(){
    $(document.body).on('click', 'a[href="#"]', e => {
        e.preventDefault()
    })
    $(document.body).on('click', 'a[href="#0"]', e => {
        e.preventDefault()
    })
})

export default 'Link'
