class FormImage
{
    constructor(element) {
        this.regEls(element)
        this.addListener()
    }

    addListener() {
        this.$el.on('click', '.btn-close', e => {
            $(this.model).val('').change()
        })

        $(this.empty).on('click', e => {
            let that = this

            let opts = {
                form: this.el.dataset.form,
                accept: 'image/*',
                success(res) {
                    $(that.model).val(res.data.url).change()
                },
                error(error) {
                    console.log(error)
                },
                progress(percent) {
                    if(!percent || percent >= 100)
                        return that.progress.classList.add('d-none')

                    that.progress.classList.remove('d-none')
                    that.progBar.style.width = percent + '%'
                }
            }
            let picker = new FilePicker(opts)
            picker.start()
        })

        $(this.image).on('click', e => {
            let url = this.model.value.trim()
            if(!url)
                return

            let img = document.createElement('img')
            img.src = url
            let viewer = new Viewer(img, {
                navbar: false,
                title: false,
                toolbar: false,
                movable: true,
                rotatable: false,
                scalable: false
            }).show()
        })

        $(this.model)
            .change(e => this.updatePreview())
            .change()
    }

    regEls(element) {
        this.$el      = $(element)
        this.el       = element
        this.model    = element.nextElementSibling
        this.empty    = this.$el.children('.empty').get(0)
        this.preview  = this.$el.children('.preview').get(0)
        this.image    = this.$el.find('.image').get(0)
        this.progress = this.$el.children('.progress').get(0)
        this.progBar  = $(this.progress).children('.progress-bar').get(0)
    }

    showEmpty() {
        this.preview.classList.add('d-none')
        this.empty.classList.remove('d-none')
    }

    showPreview(url) {
        this.empty.classList.add('d-none')
        this.preview.classList.remove('d-none')

        this.image.style.backgroundImage  = `url("${url}")`
    }

    updatePreview() {
        let val = this.model.value.trim()

        if (!val) {
            this.showEmpty()
        } else {
            this.showPreview(val)
        }
    }
}

$(function(){
    let els = document.querySelectorAll('.form-image')
    if(!els.length)
        return

    els.forEach(e => {
        e._formImage = new FormImage(e)
    })
})

export default FormImage
