class FilePicker
{
    constructor(options) {
        this._files    = []
        this._result   = []
        this._progress = { files: 0, current: [], total:0 }
        this._options  = options
        this._success  = false

        this._generateInput()
        this._addEventListeners()
    }

    _addEventListeners() {
        this.input.addEventListener('change', e => {
            this._files = this.input.files
            this._eventPicked(this._files)

            this._progress.total = 100 * this._files.length

            for(let i=0; i<this._files.length; i++) {
                let file = this._files[i]

                this._eventProgress(5, i)

                // check if server already has this file
                this._remoteFindByHash(file, i, res => {
                    if (!res.error && res.data)
                        return this._eventUploaded(res, i)

                    if (res.error)
                        return this._eventError(res, i)

                    this._remoteSend(file, i, res => {
                        if (!res.error && res.data)
                            return this._eventUploaded(res, i)

                        if (res.error)
                            return this._eventError(res, i)

                        // I've no idea what happend here
                        console.log(res)
                    })
                })
            }
        })
    }

    _eventError(res, index) {
        this._result[index] = res
        this._progress.files++
        this._eventProgress(100, index)

        if (!res.message)
            res.message = 'Unreadable server response'

        if(this._options.error)
            this._options.error(res, index)
    }

    _eventPicked(files) {
        if (this._options.picked)
            this._options.picked(files)
    }

    _eventProgress(total, index) {
        if (!this._progress.current[index])
            this._progress.current[index] = 0

        this._progress.current[index] = total
        let current = 0
        this._progress.current.forEach(e => {
            current += e
        })

        current = Math.round( current / this._progress.total * 100 )

        if (this._options.progress)
            this._options.progress(total, index, current)
    }

    _eventSuccess() {
        if (this._progress.files !== this._files.length)
            return

        if (!this._options.success)
            return

        let result = this._result
        if (!this._options.multiple)
            result = result[0]

        this._options.success(result)
    }

    _eventUploaded(res, index) {
        this._result[index] = res
        this._progress.files++
        this._eventProgress(100, index)

        if(this._options.uploaded)
            this._options.uploaded(res, index)

        this._eventSuccess()
    }

    _generateInput() {
        let input = document.createElement('input')
        input.type = 'file'

        if(this._options.accept)
            input.accept = this._options.accept

        if(this._options.multiple)
            input.multiple = true

        this.input = input
    }

    _remoteFindByHash(file, index, callback) {
        let fread = new FileReader
        let error = {
            error: true,
            message: 'Fail on reading the file'
        }

        fread.onerror = e => callback(error)

        fread.onload = e => {
            if(file.size != e.target.result.length)
                return callback(error)

            this._eventProgress(10, index)
            let hash = SparkMD5.hashBinary(e.target.result)

            this._eventProgress(15, index)
            $.get(AConf.upload.filter, {hash}, res => {
                if (res.error)
                    return callback(res)

                res.data = res.data[0] || null

                callback(res)
            })
        }

        fread.readAsBinaryString(file)
    }

    _remoteSend(file, index, callback) {
        this._eventProgress(20, index)
        this._remoteValidateFile(file, index, res => {
            if (res.error)
                return callback(res)

            this._eventProgress(25, index)
            if(file.size < 500000)
                return this._remoteSendSingle(file, index, callback)

            this._remoteSendChunks(file, index, res.data.token, callback)
        })
    }

    _remoteSendChunks(file, index, token, callback) {
        let that = this
        let uploader = new FileUploader({
            url     : window.AConf.upload.chunk,
            files   : {file},
            fields  : {
                form: this._options.form,
                token: token
            },
            chunks  : {
                minSize: 1,
                after(up, res, callback){
                    let body = {
                        form  : that._options.form,
                        token : token,
                        name  : file.name
                    }

                    $.ajax({
                        type       : 'POST',
                        url        : window.AConf.upload.finalize,
                        data       : JSON.stringify(body),
                        contentType: 'application/json',
                        dataType   : 'json',
                        success(res) {
                            callback(res)
                        },
                        error() {
                            callback({
                                error: true,
                                message: 'Fail on trying to upload the file to server'
                            })
                        }
                    })
                }
            },
            onChankUploaded(up, xhr, res, callback){
                if(res.error)
                    return callback(res.message)

                callback(true)
            },
            onProgress(up, percent){
                let val = ( 30 + ( ( percent / 100 ) * 60 ) )
                that._eventProgress(val, index)
            },
            onSuccess(up, xhr, res){
                that._eventProgress(100, index)
                if (typeof res !== 'object') {
                    return callback({
                        error: true,
                        messag: 'Fail on parsing server response'
                    })
                }

                callback(res)
            },
            onError(up){
                callback({
                    error: true,
                    message: 'Fail on trying to upload the file to server'
                })
            }
        })

        uploader.send()
    }

    _remoteSendSingle(file, index, callback) {
        let that = this
        let uploader = new FileUploader({
            url     : window.AConf.upload.target,
            files   : {file},
            fields  : {form: this._options.form},
            onSuccess(up, xhr, res){
                if (typeof res === 'object')
                    return callback(res)

                return callback({
                    error: true,
                    message: 'Fail on parsing server response'
                })
            },
            onError(up){
                callback({
                    error: true,
                    message: 'Fail on trying to upload the file to server'
                })
            }
        })

        uploader.send()
    }

    _remoteValidateFile(file, index, callback) {
        let body = {
            form: this._options.form,
            file: {
                size: file.size,
                type: file.type,
                name: file.name,
                width : null,
                height: null
            }
        }

        let error = {
            error: true,
            message: 'Server unreachable'
        }

        let makeRequest = () => {
            $.ajax({
                type       : 'POST',
                url        : window.AConf.upload.validate,
                data       : JSON.stringify(body),
                contentType: 'application/json',
                dataType   : 'json',
                success    : res => {
                    callback(res)
                },
                error      : e => {
                    callback(error)
                }
            })
        }

        if(/image\//.test(file.type)){
            let img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                body.file.width = img.width
                body.file.height = img.height

                makeRequest()
            }
        }else{
            makeRequest()
        }
    }

    start() {
        this.input.click()
    }
}

window.FilePicker = FilePicker

export default FilePicker
