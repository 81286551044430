$(function(){
    $('#marquee_post').removeClass('d-none')
    $('#marquee_post-ticker').ticker({
        speed:.2,
        controls:false,
        titleText:"",
        displayType:"reveal",
        direction:"ltr",
        pauseOnItems:2000,
        fadeInSpeed:600,
        fadeOutSpeed:300
    })
})

export default 'Ticker'
