class Confirm
{
    constructor(element) {
        this.el = element
        this.$el = $(element)
    }

    _makeModal() {
        let question = this.el.dataset.confirm || 'Are you sure want to do that?'
        let button   = this.el.dataset.button || 'Confirm'
        let style    = this.el.dataset.style || 'primary'

        let tmpl = `
            <div class="modal fade" tabindex="-1">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body"></div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-${style} btn-confirm">Save</button>
                  </div>
                </div>
              </div>
            </div>`

        if (!this._modal){
            this._modal = $(tmpl).get(0)
            $(this._modal)
                .find('.btn-confirm')
                .click(e => {
                    this.el.dataset.confirmed = true
                    this.el.click()
                })
        }

        $(this._modal).find('.modal-body').text(question)
        $(this._modal).find('.btn-confirm').text(button)
    }

    ask() {
        if (!this._modal)
            this._makeModal()

        $(this._modal).modal('show')
    }
}

$(e => {
    $(document.body).on('click', '.action-cofirm', function(event) {
        if (this.dataset.confirmed)
            return true

        event.preventDefault()
        let $this = $(this)
        if (!$this.data('action-confirm'))
            $this.data('action-confirm', new Confirm(this))
        $this.data('action-confirm').ask()
    })
})

export default Confirm
