$(function(){
    let menuId = 'header-menu-mobile_items'
    let btnId  = 'header-menu-mobile_toggler-btn'
    let menuEl = document.querySelector(`#${menuId}`)
    let menu = new MmenuLight(menuEl)
    let drawer = menu.offcanvas({position:'right'})

    menu.navigation({theme:'dark', title: 'MataLelaki'})

    menuEl.classList.remove('d-none')

    $(document.body).on('click', `#${btnId}`, () => drawer.open())

    if (/sidemenu=1/.test(location.hash)){
        if (document.body.offsetWidth < 768)
            drawer.open()
    }

})

export default 'MMenu';
