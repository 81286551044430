$(function(){
    let cnt = document.querySelectorAll('.viewerjs-container')
    if(cnt.length) {
        cnt.forEach(e => {
            new Viewer(e, {
                rotatable: false,
                title: false,
                url(img) {
                    let src = img.src
                    if(img.dataset.splideLazy)
                        src = img.dataset.splideLazy
                    return src
                },
                toolbar: {
                    zoomIn: 1,
                    zoomOut: 1,
                    oneToOne: 1,
                    reset: 0,
                    prev: 1,
                    play: 1,
                    next: 1
                }
            })
        })
    }

    let sgl = document.querySelectorAll('.viewerjs-item')
    if(sgl.length) {
        sgl.forEach(e => {
            new Viewer(e, {
                rotatable: false,
                toolbar: false,
                navbar: false,
                title: false,
                url(img) {
                    return img.src.replace(/_[0-9]+x[0-9]+/, '')
                }
            })
        })
    }

    let sc = document.querySelector('.section-content')
    if(sc) {
        new Viewer(sc, {
            rotatable: false,
            toolbar: {
                zoomIn: 1,
                zoomOut: 1,
                oneToOne: 1,
                reset: 0,
                prev: 1,
                play: 1,
                next: 1
            },
            title: false
        })
    }
})

export default 'Viewer'
