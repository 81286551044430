$(function(){
    let cont = document.querySelector('#sidebar_container')

    if (!cont)
        return

    let stick_opts = {
        offset_top: 44
    };

    $(cont).stick_in_parent(stick_opts)

    // now, let fix accidentally sidebar content resized
    const observer = new ResizeObserver(e => {
        $(cont).trigger("sticky_kit:detach")
        $(cont).data('sticky_kit', false)
        let contHeight = cont.offsetHeight
        let sideHeight = cont.parentNode.offsetHeight

        if(sideHeight > contHeight)
            $(cont).stick_in_parent(stick_opts)
    })

    observer.observe(cont)
})

export default 'Sidebar'
