$(function(){
    $(document.body).on('click', '.shared-to', function(e){
        let target = this.dataset.target
        let link   = location.href
        let options = {
            facebook: {
                url: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
                window: 'width=580,height=296'
            },
            twitter: {
                url: `https://twitter.com/share?text=${link}`,
                window: 'width=550,height=235'
            },
            whatsapp: {
                url: `https://api.whatsapp.com/send?text=${link}`,
                window: 'width=550,height=450'
            },
            telegram: {
                url: `https://t.me/share/url?url=${link}`,
                window: 'width=550,height=350'
            }
        }

        if (!options[target])
            return

        let opts = options[target]
        window.open(opts.url, target, opts.window)
        e.preventDefault()
    })
})

export default 'Share'
