class FormList {
    constructor(el) {
        this._editIndex = null

        this._populateElements(el)
        this._renderEditor()
        this._addEventListeners()

        $(this._model).change()
    }

    _addItemActions(item) {
        let html = `
            <div class="form-list-item_action">
                <a class="action remove" href="#" data-action="remove" title="Remove">
                    <i class="fas fa-trash-alt"></i>
                </a>
                <a class="action edit" href="#" data-action="edit" title="Edit">
                    <i class="fas fa-edit"></i>
                </a>
            </div>`

        $(item).append(html)
    }

    _addEventListeners() {
        $(this._btnAdd).click(e => {
            this._editIndex = null
            this._fillEditor({})
            $(this._editor).modal('show')
        })

        $(this._model).change(e => {
            this._updateList()
        })

        $(this._editor).submit(e => {
            $(this._editor).modal('hide')
            let result = {}

            for(let i=0; i<this._editor.elements.length; i++) {
                let el = this._editor.elements[i]
                if(!el.name || !el.value)
                    continue

                result[el.name] = el.value
            }

            this._setItem(result)

            return false
        })

        $(this._list).on('click', '.action', e => {
            this._handleAction(e.target)

            return false
        })
    }

    _editItem(list, index) {
        this._editIndex = index

        this._fillEditor(this.value[index])
        $(this._editor).modal('show')
    }

    _fillEditor(item) {
        for(let i=0; i<this._editor.elements.length; i++) {
            let el = this._editor.elements[i]
            if(!el.name)
                continue

            el.value = item[el.name] || ''
        }
    }

    _handleAction(el) {
        if(el.tagName === 'I')
            el = el.parentNode

        let action = el.dataset.action
        let litem = el.parentNode

        // find list item
        while(true) {
            if(litem.classList.contains('form-list-item')){
                break
            }

            litem = litem.parentNode
        }

        let fList = $(litem).data('form-list')

        if(action === 'remove'){
            this._removeList(litem, litem.dataset.index)
        }else if(action === 'edit') {
            this._editItem(litem, litem.dataset.index)
        }
    }

    _hideList() {
        this._empty.classList.remove('d-none')
        this._list.classList.add('d-none')
    }

    _populateElements(el) {
        let $el = $(el)

        this._container = el
        this._model  = $el.children('.form-control').get(0)
        this._list   = $el.children('.list-group').get(0)
        this._empty  = $el.children('.empty').get(0)
        this._tmpl   = document.querySelector(`.${this._model.name}-list-itme`)
        this._btnAdd = $el.find('.btn-add').get(0)
    }

    _renderEditor() {
        let template = $(this._container).children('.editor').get(0)
        let modal = template.content.cloneNode(true)

        this._editor = modal.querySelector('.modal')
        document.body.appendChild(this._editor)
    }

    _removeList(item, index) {
        this.value.splice(index, 1)
        this._model.value = JSON.stringify(this.value)

        $(item).slideUp('fast', e => {
            $(this._model).change()
        })
    }

    _renderItem(item, index) {
        let html  = this._tmpl.content.cloneNode(true)
        let props = html.querySelectorAll('.value')
        let litem = html.querySelector('.list-group-item')

        litem.classList.add('form-list-item')
        litem.dataset.index = index
        $(litem).data('form-list', this)
        this._addItemActions(litem)

        props.forEach(e => {
            let prop = e.dataset.property
            e.innerText = item[prop] || '-'
        })

        this._list.appendChild(html)
    }

    _showList() {
        this._empty.classList.add('d-none')
        this._list.classList.remove('d-none')
    }

    _setItem(item) {
        if (null === this._editIndex)
            this.value.push(item)
        else
            this.value[this._editIndex] = item

        this._model.value = JSON.stringify(this.value)
        $(this._model).change()
    }

    _updateList() {
        this.value = JSON.parse(this._model.value || '[]')

        if(!this.value.length)
            return this._hideList()

        this._list.innerHTML = ''
        this.value.forEach((e, i) => {
            this._renderItem(e, i)
        })

        this._showList()
    }
}

$(function(){
    let els = document.querySelectorAll('.form-list')
    if(!els.length)
        return

    els.forEach(e => {
        $(e).data('form-list', new FormList(e))
    })
})

export default FormList
